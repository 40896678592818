import { useLocation, useNavigate } from "react-router-dom";
import { CommonConfig } from "constant";
import "assets/css/style.css";

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import {
  Add,
  ArrowDownward,
  CancelScheduleSendTwoTone,
  ControlCameraTwoTone,
} from "@mui/icons-material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import cogoToast from "cogo-toast";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import { TextField } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { NotesComponent } from "layouts/component/Notes";
import moment from "moment";
import Moment from "react-moment";
import "../lead/Lead.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import { indexof } from "stylis";
import pxToRem from "assets/theme/functions/pxToRem";
import { width } from "@mui/system";

function Leadlist(props) {
  let navigate = useNavigate();

  // if (localStorage.getItem("userData")) {
  //   // alert("Hello");
  // } else {
  //   // alert("Hello2")
  //   navigate("/");
  // }
  let i = 1;
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [name, setName] = useState("");
  const [cityOrState, setCityOrState] = useState("");
  const [userType, setUserType] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");

  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(null);

  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [appointmentdelID, setappointmentdelID] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [leadID, setLeadID] = useState(null);
  const [getNotesLeadId, setGetNotesLeadId] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [notesListData, setNotesListData] = useState([]);

  const [addNotesOpenDialog, setAddNotesOpenDialog] = useState(false);
  const [chNotes, setChNotes] = useState(null);

  // let loginUser;

  // loginUser = JSON.parse(localStorage.getItem("userData"));

  var userTypeMessage = "Lead List";

  // console.log("Testing12 ", loginUser.PersonId);

  const columns = [
    {
      name: "Lead Number",
      selector: "LeadNumber",
      filter: true,
      sortable: true,
    },

    {
      name: "Lead Title",
      selector: "LeadTitle",
      filter: true,
      sortable: true,
    },

    {
      name: "Lead  Date",
      body: (data) => {
        if (data.LeadDate) {
          return CommonConfig.formatDate(data.LeadDate);
        }
        return "";
      },
      selector: "LeadDate",
      filter: true,
      sortable: true,
    },

    {
      name: "Requirement Type",
      selector: "RequirementType",
      filter: true,
      sortable: true,
    },

    {
      name: "Handler",
      selector: "HandlerPersonid",
      filter: true,
      sortable: true,
    },

    {
      name: "Stage",
      selector: "Stage",
      filter: true,
      sortable: true,
    },

    {
      name: "Follow Up Date",
      body: (data) => {
        if (data.StartDate) {
          return CommonConfig.formatDate(data.StartDate);
        }
        return "";
      },
      selector: "FollowUpDate",
      filter: true,
      sortable: true,
    },

    {
      name: "Follow Up Time",
      body: (data) => {
        if (data.StartDate) {
          // return moment(data.StartDate).format("hh:mm a");
          return moment(data.StartDate).utc().format("hh:mm A");
        }
        return "";
      },
      selector: "FollowUpTime",
      filter: true,
      sortable: true,
    },

    {
      name: "Last Updated On",
      body: (data) => {
        if (data.UpdatedOn) {
          return CommonConfig.formatDate(data.UpdatedOn);
        }
        return "";
      },
      selector: "LastUpdatedOn",
      filter: true,
      sortable: true,
    },

    {
      name: "Updated By",
      selector: "UpdatedBy",
      filter: true,
      sortable: true,
    },
    {
      name: "Note",
      body: (data) => noteTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      filter: true,
      sortable: true,
    },

    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const noteTemplate = (data) => {
    console.log("datatatatatat", data);
    return (
      <div>
        <span tooltip="Show Notes" onClick={() => getNotes(data)} className="notes-label">
          3
        </span>
      </div>
    );
  };

  const actionTemplate = (data) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-user-edit"
          className="p-button-warning"
          onClick={() => editUser(data)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Lead"
          tooltipOptions={{ position: "bottom" }}
        />

        <PButton
          type="button"
          icon="pi pi-users"
          // className="customeStylePButton"
          onClick={() => Notes(data.LeadID)}
          style={{ marginRight: ".5em" }}
          tooltip="Follow UP"
          tooltipOptions={{ position: "bottom" }}
        ></PButton>
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    UserType: "",
  });

  const getData = (props) => {
    setLoader(!loader);
    axios({
      url: CommonConfig.ApiUrl + "authentication/getLeadList",

      method: "get",
      headers: {},
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data?.success === true) {
          console.log(res);
          setLoader(false);
          setData(res.data.data[0]);
          console.log("Data...", data);
        }
        console.log("test...hhghghg", res.data.Data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // const CancelDelete = () => {
  //   setSelectedMediumType("");
  //   setOpenDialog(false);
  // };
  // const selectedRow = (selectedRow = {}) => {
  //   debugger;
  //   setappointmentdelID(selectedRow.LeadID);
  //   console.log("........,new", appointmentdelID);
  //   setOpenDialog(true);
  // };
  // const deleteUser = (selectedRow = {}) => {
  //   debugger;
  //   setSelectedMediumType(false);
  //   console.log("........,,,ddddd", appointmentdelID);

  //   if (selectedMediumType == "Yes") {
  //     document.getElementById("ServicesError").style.display = "none";
  //     let inputData = {
  //       LeadID: appointmentdelID,
  //     };
  //     console.log("inputData...", inputData);
  //     axios({
  //       url: CommonConfig.ApiUrl + "authentication/deleteLeadBYID",
  //       method: "POST",
  //       headers: {},
  //       data: inputData,
  //     })
  //       .then((res) => {
  //         if (res.data?.success) {
  //           console.log("test222", res.data);
  //           if (res.data.success === true) {
  //             cogoToast.success("Lead Removed Successfully.");
  //             setOpenDialog(false);
  //             getData();
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else if (selectedMediumType == "No") {
  //     document.getElementById("ServicesError").style.display = "none";
  //     setOpenDialog(false);
  //   } else {
  //     document.getElementById("ServicesError").style.display = "block";
  //   }
  // };

  const editUser = async (row = {}) => {
    setLoader(!loader);
    const { LeadID } = row;
    try {
      const data = await axios(CommonConfig.ApiUrl + "authentication/getLeadListBYID", {
        method: "POST",
        data: { LeadID },
      }).then((res) => {
        setLoader(!loader);
        var userdata = {
          ...res.data.data[0][0],
          dOC: res.data.data[1],
        };

        var documentdata = [];

        // documentdata.push(res.data.data[1]);
        // var newdata = userdata.concat(documentdata);
        // documentdata.map((value) => {
        //   userdata.push(value);
        // });
        console.log(",,,,,,,,,ggg,,", userdata);
        navigate("/layouts/lead/Index", {
          state: res.data.data[0][0],
        });
      });

      // navigate("/layouts/referal/refEdit", {
      //   state: data,
      // });
    } catch (error) {
      console.log({ error });
    }
  };

  function getNotes(selectedRow) {
    console.log("selectedRowQ", selectedRow.LeadID);
    setGetNotesLeadId(selectedRow.LeadID);
    setOpenDialog(true);

    let noteReqData = {
      EntityType: "Lead",
      EntityId: selectedRow.LeadID,
      CurrentUser: "",
    };
    axios({
      url: CommonConfig.ApiUrl + "authentication/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log("getNotesLeadId", getNotesLeadId);

  function Notes(selectedRow) {
    console.log("Bhanunanau", selectedRow);
    getNoteList(selectedRow);
    console.log("selectedRowQ", selectedRow);
    setLeadID(selectedRow);
    setAddNotesOpenDialog(true);
  }

  console.log("leadIDQQQA", leadID);

  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: "Lead",
      EntityId: leadID,
      CurrentUser: "",
      IsFlag: 0,
      NoteType: "Lead",
      NoteTitle: "",
      NoteText: chNotes,
    };
    debugger;
    console.log("formatteddata..", formattedData);
    axios({
      url: CommonConfig.ApiUrl + "authentication/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(leadID);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("leadIDza1", leadID);
  const getNoteList = (selectedRow) => {
    console.log("leadIDza", selectedRow);
    let noteReqData = {
      EntityType: "Lead",
      EntityId: selectedRow,
      CurrentUser: "",
    };
    console.log("noteReqDataQ", noteReqData);
    axios({
      url: CommonConfig.ApiUrl + "authentication/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesListData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("leadIDleadID", leadID);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {/* 
      "Name": "",
  "CityOrState": "",
  "UserType":"",
  "PhoneOrEmail":"",
  "PersonIdNumber":"" */}
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              person_add
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {userTypeMessage}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="card">
            <div className="table-custom">
              <DataTable
                scrollable
                value={data}
                showGridlines
                scrollHeight="600px"
                scrollWidth="600px"
                responsiveLayout="scroll"
                paginator
                rows={10}
                totalRecords={data ? data.length : 0}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No records found"
                // paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
                filterDisplay="row"
              >
                {getColoumnsRow()}
              </DataTable>
            </div>
          </div>
        </MDBox>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
          <DialogTitle>Notes</DialogTitle>
          <DialogContent
            style={{
              paddingTop: "15px",
              margin: "25px",
            }}
          >
            <NotesComponent entityType="Lead" entityId={getNotesLeadId} NotesData={notesData} />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={() => setOpenDialog(false)} color="secondary">
              OK
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog open={addNotesOpenDialog} onClose={() => setAddNotesOpenDialog(false)} fullWidth>
          <DialogTitle>Notes</DialogTitle>
          <DialogContent
            style={{
              paddingTop: "15px",
              margin: "25px",
            }}
          >
            <Card sx={{ width: "100%", mt: 6 }}>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="primary"
                  color="white"
                  shadow="md"
                  borderRadius="xl"
                  ml={3}
                  mt={-2}
                >
                  <Icon fontSize="medium" color="inherit">
                    warning_amber
                  </Icon>
                </MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  Notes
                </MDTypography>
              </MDBox>
              <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
                <div className="notes-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td>
                        <td className="nt-comment">
                          <div className="reg_input mb-0">
                            <TextField
                              onChange={(e) => setChNotes(e.target.value)}
                              value={chNotes ? chNotes : ""}
                              name="notes"
                              multiline
                              rows={2}
                              // maxRows={4}
                              label="Notes Text*"
                              id="regFirstName"
                              fullWidth
                            />
                            <span class="errorTC" id="NotesError">
                              Please Write Notes.
                            </span>
                          </div>
                        </td>
                        <td className="nt-action">
                          <PButton
                            type="button"
                            icon="pi pi-plus"
                            className="p-button-primary AddNotes"
                            onClick={() => saveNotes()}
                            tooltip="Add Notes"
                            tooltipOptions={{ position: "bottom" }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <NotesComponent entityType="Lead" entityId={leadID} NotesData={notesListData} />
              </MDBox>
            </Card>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={() => setAddNotesOpenDialog(false)} color="secondary">
              Ok
            </MDButton>
          </DialogActions>
        </Dialog>
      </Card>
      {/* <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
        <DialogTitle>Remove Lead</DialogTitle>
        <DialogContent>
          <Grid item md="12">
            <div className="reg_input check_value mb-0">
              <InputLabel className="reg_lable mt-5 mb-2">
                Are You Sure to Remove this Lead.?
              </InputLabel>
              <div>
                <span class="servicesError" id="ServicesError">
                  Please select any one option.
                </span>
              </div>
              <RadioGroup
                aria-label="remove"
                name="Remove"
                row="true"
                onChange={(e) => setSelectedMediumType(e.target.value)}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                  label="Yes"
                  name="Remove"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                  label="No"
                  name="Remove"
                />
              </RadioGroup>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
            Back
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => {
              deleteUser();
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog> */}
    </DashboardLayout>
  );
}

export default Leadlist;
