import React, { useState, useEffect } from "react";
import "assets/css/style.css";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import MDBox from "components/MDBox";
import DateFnsUtils from "@date-io/date-fns";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import select from "assets/theme/components/form/select";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import cogoToast from "cogo-toast";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Pie, Bar } from "react-chartjs-2";
import Tooltip from "@mui/material/Tooltip";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@material-ui/pickers";
import MDDatePicker from "components/MDDatePicker";
import { CommonConfig } from "constant";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../node_modules/primereact/resources/themes/lara-light-indigo/theme.css";
import "../../../../node_modules/primereact/resources/primereact.css";
import "primeicons/primeicons.css";

function AppointmentReport(props) {
  let navigate = useNavigate();
  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }

  const { state } = useLocation();
  let PatientData = state ? state.PatientData : null;
  console.log("PatientData ", PatientData);
  const paginatorLeft = <PButton type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <PButton type="button" icon="pi pi-cloud" className="p-button-text" />;

  const [data, setData] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState("All");
  const [fromDate, setfromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [clientName, setClientName] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [status, setStatus] = useState("Pending");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMediumType, setSelectedMediumType] = useState(null);
  const [managedData, setManagedData] = useState(null);
  const [chManagedBy, setChManagedBy] = useState(null);
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [appointmentID, setappointmentID] = useState(null);
  const [isEditEnabled, setIsEditEnabled] = useState(state ? true : false);
  const [loader, setLoader] = React.useState(false);
  const [pieData, setPieData] = useState({});
  const [initialPieData, setInitialPieData] = useState();

  const columns = [
    {
      name: "Appointment Numbers",
      selector: "AppointmentNumber",
      filter: true,
      sortable: true,
    },
    // {
    //   name: "Appointments Date/Time/Slot",
    //   // body: (data) => {
    //   //   if (data.TimeSlot) {
    //   //     return data.TimeSlot;
    //   //   }
    //   //   return moment(data.AppointmentDate).format("DD-MM-YYYY") + " " + data.AppointmentTime;
    //   // },
    //   // selector: "AppointmentDate",
    //   // selector: "AppointmentTime",
    //   selector: "AppointmentDateTime",
    //   filter: true,
    //   sortable: true,
    // },
    {
      name: "Clients",
      // body: (data) => {
      //   return data?.FirstName + " " + data?.LastName;
      // },
      // selector: data => {dataFirstName}{data.LastName},
      selector: "PatientName",
      filter: true,
      sortable: true,
    },
    {
      name: "Phone numbers",
      selector: "PhoneNumber",
      filter: true,
      sortable: true,
    },
    {
      name: "Emails",
      selector: "Email",
      filter: true,
      sortable: true,
    },
    {
      name: "Services",
      selector: "ServiceList",
      filter: true,
      sortable: true,
    },
    {
      name: "Appointment Modes (Medium)",
      selector: "AppointmentMode",
      filter: true,
      sortable: true,
    },
    {
      name: "Status",
      selector: "Status",
      filter: true,
      sortable: true,
    },
    {
      name: "Managed By",
      selector: "ManagedByName",
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const actionTemplate = (row) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => EditData(row)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Appointment"
          tooltipOptions={{ position: "bottom" }}
        />
        {CommonConfig.isInternalStaff() === 1 ? (
          <PButton
            type="button"
            icon="pi pi-user-edit"
            onClick={() => EditAssigneeData(row)}
            tooltip="Assign Appointment"
            tooltipOptions={{ position: "bottom" }}
          />
        ) : null}
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  useEffect(() => {
    getPieData();
    getData();
    getInternalStaff();
  }, []);

  const getPieData = () => {
    let formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getAppointmentPieData",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        setInitialPieData(res.data);
        const label = [];
        const data = [];
        for (var i of res.data.data[0]) {
          label.push(i.Name);
          data.push(i.CountNumber);
        }
        setPieData({
          datasets: [
            {
              data: data,
              backgroundColor: CommonConfig.chartColors,
              hoverBackgroundColor: CommonConfig.chartColors,
            },
          ],
          labels: label,
        });
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const getData = () => {
    setLoader(!loader);
    let FromDate = fromDate ? moment(fromDate).format("YYYY-MM-DD") : "";
    let ToDate = toDate ? moment(toDate).format("YYYY-MM-DD") : "";

    let formattedData = {
      ManagedBy: selectedAppointment == "All" ? "" : CommonConfig.getCurrentUserId(),
      FromDate: FromDate,
      ToDate: ToDate,
      ClientName: clientName,
      ClientPhoneOrEmail: phoneOrEmail,
      AppointmentStatus: status,
    };

    let formattedDataT = { formattedData, CurrentUser: CommonConfig.getCurrentUserId() };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/getBookAppointmentList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: formattedDataT,
    })
      .then((res) => {
        console.log("Hello147 ", res.data);
        if (res.data?.success) {
          setLoader(false);
          console.log("-------------", data);

          // for(let i = 0 ; i < res.data.Data[0].length ; i++){
          //   // var data = res.data.Data[0][i]["Service"]

          //   res.data.Data[0][i]["Service"] = (res.data.Data[0][i]["Service"]).replace(/~/g, ',')
          //   console.log(res.data.Data[0][i]["Service"]);
          //   // data = data.replace(/~/g, ',')

          //   // var nameArr = temp.split('~');
          //   // console.log(nameArr);

          // }
          setData(res.data.data || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInternalStaff = () => {
    axios({
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {},
    })
      .then((res) => {
        if (res.data.success) {
          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
        }
      })
      .catch((error) => {});
  };

  const deleteData = (selectedRow = {}) => {
    // const {AppointmentId, EntityID}=inputData;
    console.log("........,,,", selectedRow);
    let inputData = {
      AppointmentId: selectedRow.AppointmentId,
      SecurityUserId: selectedRow.EntityID,
      // AddressId:selectedRow.AddressId
    };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/deleteAppointment",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      // Attaching the form data
      data: inputData,
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data?.success) {
          console.log("test22", res.data);
          getData();
        }
      })
      // Catch errors if any
      .catch((error) => {
        console.log(error);
      });
  };
  const onManagedByChangeStatusChanged = (e, value) => {
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };
  const onDialogSaveStatus = () => {
    console.log("selectedMediumType", selectedMediumType);
    if (selectedMediumType == null) {
      document.getElementById("ServicesErrorSelectAssign").style.display = "block";
    } else {
      document.getElementById("ServicesErrorSelectAssign").style.display = "none";

      if (
        selectedMediumType == "Assigned Other Member" &&
        chManagedBy == null &&
        chManagedByLabel == null
      ) {
        document.getElementById("ServicesErrorAssign").style.display = "block";
      } else {
        // debugger;
        let UpdateAssignee;

        UpdateAssignee = {
          AppointmentID: appointmentID,
          AssignToStaffID:
            selectedMediumType == "Assigned Other Member"
              ? chManagedBy
                ? chManagedBy
                : PatientData?.ManagedBy
              : CommonConfig.getCurrentUserId(),
          Note: chNotes ? chNotes : "",
          CurrentUser: CommonConfig.getCurrentUserId(),
        };

        console.log("Before save", UpdateAssignee);

        axios({
          // Endpoint to send files
          url: CommonConfig.ApiUrl + "Appointment/performAppointmentOperation",
          method: "POST",
          headers: {},
          data: UpdateAssignee,
        })
          .then((res) => {
            if (res.data.success) {
              cogoToast.success("Appointment Assigned Successfully.");
              setOpenDialog(false);
              getData();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const EditData = (selectedRow = {}) => {
    setLoader(!loader);
    let inputData = { AppointmentID: selectedRow.AppointmentID };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/getBookAppointmentById",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      // Attaching the form data
      data: inputData,
    })
      // Handle the response from backend here
      .then((res) => {
        setLoader(!loader);
        if (res.data?.success) {
          navigate("/pages/book-appointment/UserBookAppointmentToken", {
            state: { PatientData: res.data.data[0] },
          });
        }
      })
      // Catch errors if any
      .catch((error) => {
        console.log(error);
      });
  };

  const EditAssigneeData = (selectedRow = {}) => {
    setappointmentID(selectedRow.AppointmentID);
    setChManagedByLabel(selectedRow.ManagedByName);
    setChNotes("");
    setOpenDialog(true);
  };

  const clearData = () => {
    setToDate(null);
    setfromDate(null);
    setSelectedAppointment("All");
    setClientName("");
    setPhoneOrEmail("");
    setStatus("Pending");
  };

  // var id = localStorage.getItem(userData.id);
  // var deleteaccess = localStorage.getItem(userData.res[0][1].DeleteAccess);
  // var userid = localStorage.getItem(userData.res[0][1].UserID);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              assignment
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Appointment Report
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="custom-accordian">
            <Accordion>
              <AccordionSummary expandIcon={<ArrowDownward />}>Search Filters</AccordionSummary>
              <AccordionDetails>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={7}>
                      <Grid container spacing={3} sx={{ mb: 2.5 }}>
                        <Grid item xs={5}>
                          <MDButton
                            style={{ marginLeft: "20px" }}
                            onClick={() => setSelectedAppointment("My")}
                            variant="outlined"
                            color={selectedAppointment == "All" ? "secondary" : "primary"}
                          >
                            My Appointment
                          </MDButton>
                        </Grid>
                        <Grid item xs={5}>
                          <MDButton
                            onClick={() => setSelectedAppointment("All")}
                            variant="contained"
                            color={selectedAppointment == "All" ? "primary" : "secondary"}
                          >
                            All Appointments
                          </MDButton>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} sx={{ mb: 2.5 }}>
                        <Grid item xs={3}>
                          <h5>Date Range: </h5>
                        </Grid>
                        <br></br>
                        <Grid item xs={3}>
                          <div className="datepicker">
                            <MDDatePicker
                              // md-max-date={new Date()}
                              value={fromDate ? fromDate : ""}
                              input={{ placeholder: "Select From Date" }}
                              onChange={(e) => setfromDate(e[0])}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={3}>
                          <div className="datepicker">
                            <MDDatePicker
                              // md-max-date={new Date()}
                              value={toDate ? toDate : ""}
                              input={{ placeholder: "Select To Date" }}
                              onChange={(e) => setToDate(e[0])}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} sx={{ mb: 2.5 }}>
                        <Grid item xs={3}>
                          <h5>Client Name: </h5>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="reg_input">
                            <MDInput
                              value={clientName ? clientName : ""}
                              label=""
                              onChange={(e) => setClientName(e.target.value)}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} sx={{ mb: 2.5 }}>
                        <Grid item xs={3}>
                          <h5>Client Phone or Email : </h5>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="reg_input">
                            <MDInput
                              value={phoneOrEmail ? phoneOrEmail : ""}
                              label=""
                              onChange={(e) => setPhoneOrEmail(e.target.value)}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} sx={{ mb: 2.5 }}>
                        <Grid item xs={3}>
                          <h5>Status : </h5>
                        </Grid>
                        <Grid item xs={6}>
                          <RadioGroup
                            defaultValue=""
                            value={status ? status : ""}
                            name="radio-buttons-group"
                            row="true"
                            onChange={(r) => setStatus(r.target.value)}
                          >
                            <FormControlLabel
                              style={{
                                marginRight: "40px",
                              }}
                              value="Pending"
                              control={<Radio />}
                              label="Pending"
                            />
                            <FormControlLabel value="All" control={<Radio />} label="All" />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        <Grid item xs={5}>
                          <div className="reg_submit">
                            <MDButton onClick={() => getData()} variant="contained" color="primary">
                              Search
                            </MDButton>
                            <MDButton
                              sx={{ ml: 3 }}
                              onClick={() => clearData()}
                              variant="contained"
                              color="secondary"
                            >
                              Clear
                            </MDButton>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                      <Card sx={{ height: "100%" }}>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          pt={2}
                          px={2}
                        >
                          <MDTypography variant="h6">Appointments</MDTypography>
                          <Tooltip title="Filtered By Status" placement="bottom" arrow>
                            <MDButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              circular
                              iconOnly
                            >
                              <Icon>priority_high</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDBox mt={3}>
                          <Grid container alignItems="center">
                            <Grid item xs={7}>
                              <Pie data={pieData} />
                              {/* <PieChart chart={pieData} height="12.5rem" /> */}
                            </Grid>
                            {/* <Grid item xs={5}>
      <MDBox pr={1}>
        <MDBox mb={1}>
          <MDBadgeDot color="info" size="medium" badgeContent="Pending" />
        </MDBox>
        <MDBox mb={1}>
          <MDBadgeDot color="warning" size="sm" badgeContent="In Progress" />
        </MDBox>
        <MDBox mb={1}>
          <MDBadgeDot color="secondary" size="sm" badgeContent="Assigned" />
        </MDBox>
        <MDBox mb={1}>
          <MDBadgeDot color="success" size="sm" badgeContent="Completed" />
        </MDBox>
        <MDBox mb={1}>
          <MDBadgeDot color="error" size="sm" badgeContent="Canceled" />
        </MDBox>
      </MDBox>
    </Grid> */}
                          </Grid>
                        </MDBox>
                        <MDBox
                          pt={4}
                          pb={2}
                          px={2}
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                          mt="auto"
                        >
                          {/* <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
    <MDTypography variant="button" color="text" fontWeight="light">
      More than <strong>1,200,000</strong> sales are made using referral marketing, and{" "}
      <strong>700,000</strong> are from social media.
    </MDTypography>
  </MDBox>
  <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
    <MDButton color={darkMode ? "white" : "light"}>read more</MDButton>
  </MDBox> */}
                        </MDBox>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="table-custom">
            <DataTable
              scrollable
              value={data}
              showGridlines
              scrollHeight="600px"
              scrollWidth="600px"
              responsiveLayout="scroll"
              paginator
              rows={10}
              totalRecords={data ? data.length : 0}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              rowsPerPageOptions={[10, 20, 50]}
              emptyMessage="No records found"
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRight}
              filterDisplay="row"
            >
              {getColoumnsRow()}
            </DataTable>
          </div>
        </MDBox>
      </Card>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
        <DialogTitle>Assignee</DialogTitle>
        <DialogContent
          style={{
            paddingTop: "15px",
            margin: "25px",
          }}
        >
          <Grid item md="12">
            <div className="reg_input check_value mb-0">
              <InputLabel className="reg_lable mt-5 mb-2">Assignee Type:*</InputLabel>
              <br />
              <RadioGroup
                aria-label="gender"
                name="AssignedType"
                row="true"
                onChange={(e) => setSelectedMediumType(e.target.value)}
              >
                <FormControlLabel
                  value="Assigned to me"
                  // onChange={(e) => handleChange(e)}
                  control={
                    <Radio checked={selectedMediumType === "Assigned to me" ? true : false} />
                  }
                  label="Assigned to me"
                  name="AssignedType"
                />
                <FormControlLabel
                  value="Assigned Other Member"
                  // onChange={(e) => handleChange(e)}
                  control={
                    <Radio
                      checked={selectedMediumType === "Assigned Other Member" ? true : false}
                    />
                  }
                  label="Assigned Other Member"
                  name="AssignedType"
                />
              </RadioGroup>
            </div>
            <span class="errorTC" id="ServicesErrorSelectAssign">
              Please select Assign Type.
            </span>
          </Grid>
          {selectedMediumType === "Assigned Other Member" ? (
            <div>
              <Autocomplete
                disablePortal
                id="chManagedBy"
                name="chManagedBy"
                style={{
                  marginBottom: "10px",
                }}
                value={chManagedByLabel ? chManagedByLabel : ""}
                options={managedData ? managedData : []}
                onChange={onManagedByChangeStatusChanged}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    // {...register("ManagedBy")}
                    {...params}
                    label="Managed By*"
                  />
                )}
              />
              <span class="errorTC" id="ServicesErrorAssign">
                Please select Managed By.
              </span>
            </div>
          ) : null}
          {selectedMediumType === "Assigned Other Member" ? (
            <TextField
              onChange={(e) => setChNotes(e.target.value)}
              value={chNotes ? chNotes : ""}
              name="notes"
              multiline
              rows={3}
              style={{
                marginBottom: "10px",
              }}
              // maxRows={4}
              label="Notes"
              id="regFirstName"
              fullWidth
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpenDialog(false)} color="secondary">
            Back
          </MDButton>
          <MDButton
            color="primary"
            onClick={() => {
              onDialogSaveStatus();
            }}
          >
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default AppointmentReport;
