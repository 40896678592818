import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import axios from "axios";
import { CommonConfig } from "constant";
import React, { useEffect, useState } from "react";
const FileDownload = require('js-file-download');

export const DocumentDatatable = ({ EntityId, EntityType, DocumentListData = [] }) => {
  const [data, setData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [documentListData, setDocumentListData] = useState([]);
  const paginatorLeft = <PButton type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <PButton type="button" icon="pi pi-cloud" className="p-button-text" />;

  console.log("documentDatadocumentData",documentListData);
  let documentColumns = [
      {
        name: "Name",
        body: (documentListData) => actionTemplate(documentListData),
        selector: "Filename",
        filter: true,
        sortable: true,
      },
      {
        name: "Directory",
        selector: "EntityType",
        filter: true,
        sortable: true,
      },
      {
        name: "CreatedBy",
        selector: "Name",
        filter: true,
        sortable: true,
      },
      {
        name: "Action",
        body: (documentListData) => documentAction(documentListData),
  
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
    const actionTemplate = (documentListData) => {
      console.log("dddddddd",documentListData);
      let path = CommonConfig.documentUrl+ "" + documentListData.URL;
      return(
        <div><a href={path} target="_new">{documentListData.Filename}</a></div>
        );
    };
  
    const documentAction = (documentListData) => {
      return (
        <div>
          <PButton
            type="button"
            icon="pi pi-download"
            className="fa fa-download"
            onClick={() => downloadFile(documentListData)}
            style={{ marginRight: ".5em" }}
            tooltip="Download File"
            tooltipOptions={{ position: "bottom" }}
          />
        </div>
      );
    };

  const downloadFile = (documentListData) => {
    axios({
      url:  CommonConfig.documentUrl+ "" + documentListData.URL,
      method: 'GET',
      responseType: 'blob', // Important
    }).then((response) => {
        FileDownload(response.data, documentListData.Filename);
    });
    };

  const getDocumentColumns = () =>
    documentColumns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });


  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = () => {
    debugger;
    let docRequestData = {
      EntityId:EntityId,
      EntityType:EntityType,
      CurrentUser:CommonConfig.getCurrentUserId()
    };
    axios({
      url: CommonConfig.ApiUrl + "document/getDocumentListByTypeAndId",
      method: "POST",
      headers: {
      },
      data: docRequestData,
    }).then((res) => {
        if (res.data.success) {
          setDocumentListData(res.data.data[0] || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <DataTable
      scrollable
      value={documentListData ? documentListData : []}
      showGridlines
      scrollHeight="600px"
      scrollWidth="600px"
      responsiveLayout="scroll"
      paginator
      rows={10}
      totalRecords={documentListData ? documentListData.length : 0}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      rowsPerPageOptions={[10, 20, 50]}
      emptyMessage="No records found"
      paginatorLeft={paginatorLeft}
      paginatorRight={paginatorRight}
      filterDisplay="row"
    >
      {getDocumentColumns()}
    </DataTable>
  );
};
